import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { useIntl } from "react-intl";
import { BasicButton } from "@datwyler/shared-components";

const SelectionOptions = (props) => {
  const { setDialogOpened } = props;
  const intl = useIntl();

  const handleDeactivate = async () => {
    setDialogOpened("deactivateUser");
  };

  return (
    <>
      <Divider />
      <Box
        sx={{
          paddingTop: "16px",
          display: "flex",
          alignItems: "center",
          marginLeft: "24px",
        }}
      >
        <BasicButton
          onClick={handleDeactivate}
          variant={"outlined"}
          sx={{
            height: "44px",
          }}
        >
          <Typography
            sx={{
              fontFamily: "NotoSans-Medium",
              fontSize: "14px",
              fontWeight: 500,
            }}
          >
            {intl.formatMessage({ id: "deactivate" })}
          </Typography>
        </BasicButton>
      </Box>
    </>
  );
};

export default SelectionOptions;
