import { useState, useContext, useMemo, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ProfileDialog from "../../components/Dialogs/ProfileDialog";
import LogoutDialog from "../../components/Dialogs/LogoutDialog";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import {
  BasicButton,
  EditIcon,
  LogoutIcon,
  ApiContext,
  redirectToLogout,
  UserContext,
  TenantInfo,
  useTenant,
  openChangePassword,
  BasicSwitch,
  DarkModeContext,
  BasicCard,
  useUser,
  useLoadingGif,
  useCountry,
} from "@datwyler/shared-components";

const isDarkModeEnabled = true;

const Profile = (props: any) => {
  const [isEditProfileDialogOpen, setIsEditProfileDialogOpen] = useState(false);
  const [isLogoutDialogOpen, setIsLogoutDialogOpen] = useState(false);
  const { uri }: any = useContext(ApiContext);
  const { user, getUserProfile }: any = useContext(UserContext);
  const { loading: isCountriesLoading, data: countriesData } = useCountry();
  const [phoneCode, setPhoneCode] = useState("");
  const { colors }: any = useContext(DarkModeContext);
  const { fetchTenantById, fetchTenantByIdData } = useTenant();
  const intl = useIntl();
  const { LoadingGif, setIsLoading } = useLoadingGif();
  const [iconColor, setIconColor] = useState(colors.iconColor);
  const {
    updateUser,
    isUpdateUserLoading,
    updateUserResponseData,
    resetUpdateUserData,
  } = useUser();

  const existingDarkMode =
    window.localStorage?.getItem("darkMode") === "true" || false;
  const darkModeForm = useForm({
    defaultValues: { darkMode: existingDarkMode },
  });
  const darkMode = darkModeForm.watch("darkMode");

  useEffect(() => {
    setIconColor(colors.iconColor);
  }, [colors]);

  useEffect(() => {
    if (countriesData?.countries && user?.country?.id) {
      const userContactCountry = countriesData.countries.find(
        (country) => country.id === user.country?.id
      );
      setPhoneCode(userContactCountry.phoneCode);
    }
  }, [countriesData, user]);

  useEffect(() => {
    setIsLoading(isCountriesLoading || isUpdateUserLoading || false);
  }, [isUpdateUserLoading, isCountriesLoading]);

  useEffect(() => {
    if (isDarkModeEnabled) {
      window.localStorage.setItem("darkMode", darkMode.toString());
      window.dispatchEvent(new Event("darkModeChanged"));
    }
  }, [darkMode]);

  const handleLogout = () => {
    redirectToLogout(uri);
  };

  useEffect(() => {
    checkScrollBar();
  }, [fetchTenantByIdData]);

  const checkScrollBar = () => {
    const isScrollBarVisible =
      document.body.scrollHeight > document.body.clientHeight;
    if (isScrollBarVisible) {
      window.dispatchEvent(new Event("changeWidthWithScrollBar"));
    }
  };

  useMemo(() => {
    if (user?.tenant?.id) {
      fetchTenantById({
        variables: { id: user.tenant.id },
      });
    }
    checkScrollBar();
  }, [user]);

  const handleClickChangePassword = () => {
    openChangePassword(uri, user?.email);
  };

  const profileLabelStyle = {
    color: colors.subTitle,
    fontFamily: "NotoSans-Regular",
    fontSize: "14px",
    letterSpacing: "0.13px",
    lineHeight: "20px",
  };

  const profileInfoStyle = {
    color: colors.fontTitle,
    fontFamily: "NotoSans-Regular",
    fontSize: "16px",
    letterSpacing: "0.15px",
    lineHeight: "20px",
    marginTop: "5px",
  };

  const handleEditButtonOnMouseEnter = () => {
    setIconColor(colors.datwylerTeal);
  };

  const handleEditButtonOnMouseLeave = () => {
    setIconColor(colors.iconColor);
  };

  return (
    <Box
      sx={{
        paddingTop: "76px",
        paddingLeft: "24px",
        paddingRight: "24px",
        paddingBottom: "24px",
      }}
    >
      <LoadingGif />
      <ProfileDialog
        isOpen={isEditProfileDialogOpen}
        closeDialog={() => setIsEditProfileDialogOpen(false)}
        userProfile={user}
        getUserProfile={getUserProfile}
        updateUser={updateUser}
        updateUserResponseData={updateUserResponseData}
        resetUpdateUserData={resetUpdateUserData}
        isUserProfileScreen={true}
      />
      <LogoutDialog
        isOpen={isLogoutDialogOpen}
        closeDialog={() => setIsLogoutDialogOpen(false)}
        handleLogout={handleLogout}
      />
      <Typography
        sx={{
          color: colors.fontTitle,
          fontFamily: "NotoSans-SemiBold",
          fontSize: "24px",
          fontWeight: 600,
          letterSpacing: 0,
          lineHeight: "34px",
        }}
      >
        {intl.formatMessage({ id: "my_profile" })}
      </Typography>
      {isDarkModeEnabled && (
        <BasicCard
          sx={{
            marginTop: "16px",
          }}
        >
          <Box>
            <Box
              sx={{
                display: "inline-flex",
                justifyContent: "flex-start",
                verticalAlign: "middle",
              }}
            >
              <Typography sx={profileLabelStyle}>
                {intl.formatMessage({ id: "dark_mode" })}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "inline-flex",
                marginLeft: "24px",
                justifyContent: "flex-end",
                verticalAlign: "top",
              }}
            >
              <BasicSwitch form={darkModeForm} formItemLabel={"darkMode"} />
            </Box>
          </Box>
        </BasicCard>
      )}
      <BasicCard
        sx={{
          marginTop: "16px",
        }}
      >
        <Box>
          <Box
            sx={{
              display: "inline-flex",
              width: "50%",
              justifyContent: "flex-start",
              verticalAlign: "top",
            }}
          >
            <Typography
              sx={{
                color: colors.fontTitle2,
                fontFamily: "NotoSans-SemiBold",
                fontSize: "18px",
                fontWeight: 600,
                letterSpacing: 0,
                lineHeight: "20px",
              }}
            >
              {intl.formatMessage({ id: "profile_info" })}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "inline-flex",
              width: "50%",
              justifyContent: "flex-end",
            }}
          >
            <BasicButton
              variant="outlined"
              onClick={() => setIsEditProfileDialogOpen(true)}
              sx={{
                width: "138px",
                height: "44px",
              }}
              onMouseEnter={handleEditButtonOnMouseEnter}
              onMouseLeave={handleEditButtonOnMouseLeave}
            >
              <EditIcon fill={iconColor} />
              <Typography
                sx={{
                  fontFamily: "NotoSans-Regular",
                  fontSize: "14px",
                  letterSpacing: 0,
                  lineHeight: "20px",
                  marginLeft: "10px",
                }}
              >
                {intl.formatMessage({ id: "edit" })}
              </Typography>
            </BasicButton>
          </Box>
        </Box>
        <Box sx={{ marginTop: "12px" }}>
          <Box sx={{ display: "inline-block", width: "312px" }}>
            <Box>
              <Typography sx={profileLabelStyle}>
                {intl.formatMessage({ id: "first_name" })}
              </Typography>
            </Box>
            <Box>
              <Typography sx={profileInfoStyle}>{user.firstName}</Typography>
            </Box>
          </Box>
          <Box sx={{ display: "inline-block" }}>
            <Box>
              <Typography sx={profileLabelStyle}>
                {intl.formatMessage({ id: "last_name" })}
              </Typography>
            </Box>
            <Box>
              <Typography sx={profileInfoStyle}>{user.lastName}</Typography>
            </Box>
          </Box>
        </Box>
        <Box sx={{ marginTop: "43px" }}>
          <Box sx={{ display: "inline-block", width: "312px" }}>
            <Box>
              <Typography sx={profileLabelStyle}>
                {intl.formatMessage({ id: "email" })}
              </Typography>
            </Box>
            <Box>
              <Typography sx={profileInfoStyle}>{user.email}</Typography>
            </Box>
          </Box>
          <Box sx={{ display: "inline-block" }}>
            <Box>
              <Typography sx={profileLabelStyle}>
                {intl.formatMessage({ id: "contact_number" })}
              </Typography>
            </Box>
            <Box>
              <Typography sx={profileInfoStyle}>
                {phoneCode ? `+(${phoneCode}) ` : ""}
                {user.contact ? user.contact : ""}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box sx={{ marginTop: "43px" }}>
          <Box sx={{ display: "inline-block", width: "312px" }}>
            <Box>
              <Typography sx={profileLabelStyle}>
                {intl.formatMessage({ id: "role" })}
              </Typography>
            </Box>
            <Box>
              <Typography sx={profileInfoStyle}>{user.role}</Typography>
            </Box>
          </Box>
          <Box sx={{ display: "inline-block" }}>
            <Box>
              <Typography sx={profileLabelStyle}>
                {intl.formatMessage({ id: "company" })}
              </Typography>
            </Box>
            <Box>
              <Typography sx={profileInfoStyle}>{user.company}</Typography>
            </Box>
          </Box>
        </Box>
      </BasicCard>
      <TenantInfo tenant={fetchTenantByIdData?.tenantById} />
      <BasicCard
        sx={{
          marginTop: "24px",
        }}
      >
        <Box>
          <Box
            sx={{
              display: "inline-flex",
              width: "50%",
              justifyContent: "flex-start",
              verticalAlign: "top",
            }}
          >
            <Typography
              sx={{
                color: colors.fontTitle2,
                fontFamily: "NotoSans-SemiBold",
                fontSize: "18px",
                fontWeight: 600,
                letterSpacing: 0,
                lineHeight: "20px",
              }}
            >
              {intl.formatMessage({ id: "password" })}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "inline-flex",
              width: "50%",
              justifyContent: "flex-end",
            }}
          >
            <BasicButton
              variant="outlined"
              onClick={() => handleClickChangePassword()}
              sx={{
                width: "152px",
                height: "44px",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "NotoSans-Regular",
                  fontSize: "14px",
                  letterSpacing: 0,
                  lineHeight: "20px",
                }}
              >
                {intl.formatMessage({ id: "change_password" })}
              </Typography>
            </BasicButton>
          </Box>
        </Box>
      </BasicCard>
      <BasicCard
        sx={{
          marginTop: "24px",
          padding: "10px 16px",
        }}
      >
        <Box>
          <Box
            sx={{
              display: "inline-flex",
              width: "100%",
              justifyContent: "flex-start",
            }}
          >
            <BasicButton
              variant="outlined"
              onClick={() => {
                setIsLogoutDialogOpen(true);
              }}
              sx={{
                border: "none",
                width: "168px",
                height: "44px",
                backgroundColor: colors.white,
                "&:hover": {
                  border: "1px solid " + colors.red400,
                },
              }}
            >
              <LogoutIcon />
              <Typography
                sx={{
                  color: colors.red400,
                  fontFamily: "NotoSans-Regular",
                  fontSize: "14px",
                  letterSpacing: 0,
                  lineHeight: "20px",
                  marginLeft: "10px",
                }}
              >
                {intl.formatMessage({ id: "log_out" })}
              </Typography>
            </BasicButton>
          </Box>
        </Box>
      </BasicCard>
    </Box>
  );
};

export default Profile;
