import { useState, useEffect, useContext } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import { useIntl } from "react-intl";
import { useForm } from "react-hook-form";
import {
  ROLE_USER,
  ROLE_ADMIN,
  ROLE_OPERATOR,
  ACTIVE,
} from "../../utils/constants";
import { getClearAdornment } from "../../utils";
import {
  CloseIcon24p,
  BasicButton,
  DarkModeContext,
  BasicSelectWithCheckMark,
  BasicTextField,
  useSnackbar,
  useCountry,
} from "@datwyler/shared-components";

const inputLabelProps = {
  sx: {
    fontSize: "14px",
    letterSpacing: "0.01px",
    top: "3px",
  },
};

const inputProps = {
  sx: {
    height: "44px",
  },
};

const SelectDisplayProps = {
  paddingTop: "13px",
  paddingBottom: "12px",
  paddingLeft: "16px",
  height: "19px",
};

const inputLabelSx = {
  fontFamily: "NotoSans-Regular",
  fontSize: "14px",
  letterSpacing: "0.01px",
  lineHeight: "24px",
  fontWeight: 400,
};

const menuPaperSx = { width: "376px" };

const formDefaults = {
  firstName: "",
  lastName: "",
  email: "",
  role: "",
  company: "",
  country: "",
  contact: "",
};

const ProfileDialog = (props) => {
  const {
    isOpen = false,
    closeDialog,
    userProfile,
    getUserProfile = () => {},
    refetchUsers = () => {},
    tenantId,
    addUser,
    addUserResponseData,
    resetAddUserData,
    updateUser,
    updateUserResponseData,
    resetUpdateUserData,
    isUserProfileScreen = false,
  } = props;

  const intl = useIntl();
  const form = useForm({
    defaultValues: formDefaults,
  });

  const { enqueueSnackbar } = useSnackbar();
  const { loading: isCountriesLoading, data: countriesData } = useCountry();
  const [countryOptions, setCountryOptions] = useState([]);
  const { colors }: any = useContext(DarkModeContext);

  // to keep track of the input focused by the user
  const [focused, setFocused] = useState(null);

  const getCountryOptions = (countries) => {
    const tempOptions = [];
    let count = 0;
    countries?.forEach((country) => {
      tempOptions.push({
        priority: count++,
        label: country.name + ` (${country.phoneCode}) `,
        value: country.id,
      });
    });

    return tempOptions;
  };

  useEffect(() => {
    if (countriesData?.countries) {
      setCountryOptions(getCountryOptions(countriesData?.countries));
    }
  }, [countriesData]);

  useEffect(() => {
    if (userProfile) {
      form.reset({
        firstName: userProfile?.firstName || "",
        lastName: userProfile?.lastName || "",
        email: userProfile?.email || "",
        role: userProfile?.role || "",
        company: userProfile?.company || "",
        country: userProfile?.country?.id || "",
        contact: userProfile?.contact || "",
      });
    } else {
      form.reset(formDefaults);
    }
  }, [userProfile]);

  useEffect(() => {
    if (addUserResponseData?.addUser?.user) {
      enqueueSnackbar(intl.formatMessage({ id: "new_user_added" }), {
        variant: "success",
      });

      resetAddUserData();
      refetchUsers();
      handleClose();
    }
  }, [addUserResponseData]);

  useEffect(() => {
    if (updateUserResponseData?.updateUser?.user && isOpen) {
      enqueueSnackbar(
        intl.formatMessage({ id: "update_user_profile_success" }),
        {
          variant: "success",
        }
      );

      resetUpdateUserData();
      getUserProfile();
      handleClose();
    }
  }, [updateUserResponseData]);

  const roleOptions = [
    {
      priority: 1,
      label: intl.formatMessage({ id: "ADMIN" }),
      value: ROLE_ADMIN,
    },
    {
      priority: 2,
      label: intl.formatMessage({ id: "OPERATOR" }),
      value: ROLE_OPERATOR,
    },
  ];

  const inputRules = {
    required: {
      type: "required",
      value: true,
      message: intl.formatMessage({ id: "validation_required" }),
    },
  };

  const handleSave = async () => {
    const isValid = await form.trigger();

    if (isValid) {
      const submitValues = form.getValues();
      transformBeforeSubmit(submitValues);
      if (userProfile) {
        updateUser({
          variables: { input: submitValues },
        });
      } else {
        addUser({
          variables: { input: submitValues },
        });
      }
    }
  };

  const transformBeforeSubmit = (submitValues) => {
    if (userProfile?.id) {
      // update user
      submitValues.id = userProfile.id;
    } else {
      // add user
      submitValues.tenant = { id: tenantId };
    }
    if (submitValues.country) {
      submitValues.country = { id: submitValues.country };
    } else {
      delete submitValues.country;
    }
    submitValues.status = userProfile?.status || ACTIVE;
  };

  const handleClose = () => {
    setFocused(null);
    if (!isUserProfileScreen) form.reset(formDefaults);
    closeDialog();
  };

  return (
    <Dialog
      maxWidth={"sm"}
      open={isOpen}
      onClose={handleClose}
      PaperProps={{
        sx: { borderRadius: "8px", backgroundColor: colors.cardBg },
      }}
    >
      <DialogTitle sx={{ paddingTop: "26px", paddingBottom: "12px" }}>
        <Typography
          sx={{
            fontFamily: "NotoSans-SemiBold",
            fontSize: 16,
            color: colors.fontTitle,
          }}
        >
          {!!userProfile
            ? intl.formatMessage({ id: "update_profile_info" })
            : intl.formatMessage({ id: "add_user" })}
        </Typography>
        <IconButton
          onClick={handleClose}
          sx={{ position: "absolute", right: 16, top: 16 }}
        >
          <CloseIcon24p fill={colors.iconColor} />
        </IconButton>
      </DialogTitle>
      <Divider sx={{ borderColor: colors.dividerColor }} />
      <DialogContent sx={{ overflowX: "hidden" }}>
        <Box sx={{ width: "376px" }}>
          <Box>
            <BasicTextField
              label={intl.formatMessage({ id: "first_name" })}
              type={"text"}
              form={form}
              formItemLabel={"firstName"}
              variant={"outlined"}
              size={"small"}
              InputLabelProps={inputLabelProps}
              onClick={() => setTimeout(() => setFocused("firstName"), 100)}
              InputProps={{
                ...inputProps,
                endAdornment:
                  focused === "firstName"
                    ? getClearAdornment(form, "firstName", colors)
                    : null,
              }}
              rules={inputRules}
            />
          </Box>
          <Box sx={{ marginTop: "16px" }}>
            <BasicTextField
              label={intl.formatMessage({ id: "last_name" })}
              type={"text"}
              form={form}
              formItemLabel={"lastName"}
              variant={"outlined"}
              size={"small"}
              InputLabelProps={inputLabelProps}
              onClick={() => setFocused("lastName")}
              InputProps={{
                ...inputProps,
                endAdornment:
                  focused === "lastName"
                    ? getClearAdornment(form, "lastName", colors)
                    : null,
              }}
              rules={inputRules}
            />
          </Box>
          {!isUserProfileScreen && (
            <Box sx={{ marginTop: "16px" }}>
              <BasicTextField
                label={intl.formatMessage({ id: "email" })}
                type={"text"}
                form={form}
                formItemLabel={"email"}
                variant={"outlined"}
                size={"small"}
                InputLabelProps={inputLabelProps}
                onClick={() => setFocused("email")}
                InputProps={{
                  ...inputProps,
                  endAdornment:
                    focused === "email"
                      ? getClearAdornment(form, "email", colors)
                      : null,
                }}
                rules={inputRules}
              />
            </Box>
          )}
          {!isUserProfileScreen && (
            <Box sx={{ marginTop: "16px" }}>
              <BasicSelectWithCheckMark
                menuItems={roleOptions}
                form={form}
                formItemLabel={"role"}
                label={intl.formatMessage({ id: "role" })}
                rules={inputRules}
                SelectDisplayProps={SelectDisplayProps}
                inputLabelSx={inputLabelSx}
                menuPaperSx={menuPaperSx}
                onFocus={() => setFocused("null")}
              />
            </Box>
          )}
          <Box sx={{ marginTop: "16px" }}>
            <BasicTextField
              label={intl.formatMessage({ id: "company" })}
              type={"text"}
              form={form}
              formItemLabel={"company"}
              variant={"outlined"}
              size={"small"}
              InputLabelProps={inputLabelProps}
              onClick={() => setFocused("company")}
              InputProps={{
                ...inputProps,
                endAdornment:
                  focused === "company"
                    ? getClearAdornment(form, "company", colors)
                    : null,
              }}
              rules={inputRules}
            />
          </Box>
          <Box sx={{ marginTop: "16px" }}>
            <BasicSelectWithCheckMark
              menuItems={countryOptions}
              form={form}
              formItemLabel={"country"}
              label={intl.formatMessage({ id: "select_country_code" })}
              SelectDisplayProps={SelectDisplayProps}
              inputLabelSx={inputLabelSx}
              menuPropsSx={{
                maxHeight: "256px",
                maxWidth: "376px",
                marginTop: "8px",
              }}
              isLoading={isCountriesLoading}
            />
          </Box>
          <Box sx={{ marginTop: "16px" }}>
            <BasicTextField
              label={intl.formatMessage({ id: "contact_number" })}
              type={"text"}
              form={form}
              formItemLabel={"contact"}
              variant={"outlined"}
              size={"small"}
              InputLabelProps={inputLabelProps}
              onClick={() => setFocused("contact")}
              InputProps={{
                ...inputProps,
                endAdornment:
                  focused === "contact"
                    ? getClearAdornment(form, "contact", colors)
                    : null,
              }}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "flex-end", paddingBottom: "24px" }}>
        <Box sx={{ marginRight: "16px" }}>
          <BasicButton onClick={handleClose} variant={"outlined"}>
            {intl.formatMessage({ id: "cancel" })}
          </BasicButton>
        </Box>
        <Box sx={{ marginRight: "16px" }}>
          <BasicButton
            onClick={handleSave}
            variant={"contained"}
            sx={{ backgroundColor: colors.datwylerTeal }}
          >
            {intl.formatMessage({ id: "save_changes" })}
          </BasicButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default ProfileDialog;
