import { useState, useEffect } from "react";
import Router from "./Router";
import { useLocation } from "react-router-dom";
import { UserProvider, TenantIdProvider } from "@datwyler/shared-components";

const App = () => {
  const location = useLocation();
  const [tenantId, setTenantId] = useState(null);

  useEffect(() => {
    if (location?.state?.tenantId) setTenantId(location.state.tenantId);
  }, [location]);

  return (
    <TenantIdProvider tenantId={tenantId}>
      <UserProvider>{tenantId && <Router />}</UserProvider>
    </TenantIdProvider>
  );
};

export default App;
