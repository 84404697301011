import { useState, MouseEvent, useContext } from "react";
import Box from "@mui/material/Box";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { useIntl } from "react-intl";
import {
  BasicSelectWithCheckBox,
  BasicButton,
  FilterIcon,
  DarkModeContext,
  BasicTextFieldWithoutForm,
} from "@datwyler/shared-components";
import {
  ROLE_USER,
  ROLE_ADMIN,
  ROLE_OPERATOR,
  ACTIVE,
  DEACTIVATED,
} from "../../utils/constants";

const filterContainerStyle = {
  display: "inline-flex",
  height: "44px",
  marginLeft: "24px",
};

const Filters = (props) => {
  const { filterDefaults, setFilters } = props;
  const [interimFilters, setInterimFilters] = useState(filterDefaults);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const intl = useIntl();
  const { colors }: any = useContext(DarkModeContext);

  const open = Boolean(anchorEl);

  const statusOptions = [
    {
      priority: 1,
      label: intl.formatMessage({ id: ACTIVE.toLocaleLowerCase() }),
      value: ACTIVE,
    },
    {
      priority: 2,
      label: intl.formatMessage({ id: DEACTIVATED.toLocaleLowerCase() }),
      value: DEACTIVATED,
    },
  ];

  const roleOptions = [
    {
      priority: 0,
      label: intl.formatMessage({ id: "ADMIN" }),
      value: ROLE_ADMIN,
    },
    {
      priority: 1,
      label: intl.formatMessage({ id: "OPERATOR" }),
      value: ROLE_OPERATOR,
    },
  ];

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeRole = (roles: Array<string>) => {
    setInterimFilters({ ...interimFilters, roles: roles });
  };

  const handleChangeStatus = (statuses: Array<string>) => {
    setInterimFilters({ ...interimFilters, statuses: statuses });
  };

  const handleChangeCompany = (company = "") => {
    setInterimFilters({ ...interimFilters, company });
  };

  const applyFilters = () => {
    setFilters(interimFilters);
    handleClose();
  };

  const resetFilters = () => {
    setInterimFilters(filterDefaults);
  };

  return (
    <Box>
      <Box sx={{ paddingTop: "16px", display: "flex", alignItems: "center" }}>
        <Box sx={filterContainerStyle}>
          <BasicButton
            variant="outlined"
            onClick={handleClick}
            sx={{ border: "1px solid " + colors.blueGray300 }}
          >
            <FilterIcon fill={colors.iconColor} />
            <Typography
              sx={{
                fontFamily: "NotoSans-Medium",
                fontSize: "14px",
                fontWeight: 500,
                letterSpacing: 0,
                lineHeight: "20px",
                marginLeft: "10px",
              }}
            >
              {intl.formatMessage({ id: "filters" })}
            </Typography>
          </BasicButton>
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            sx={{
              marginTop: "10px",
            }}
            PaperProps={{
              sx: {
                boxShadow: "0 2px 8px 0 rgba(16,24,40,0.16)",
                borderRadius: "8px",
                backgroundColor: colors.selectDropDownBg,
              },
            }}
          >
            <Box sx={{ padding: "16px" }}>
              <Box sx={{ width: "230px" }}>
                <BasicSelectWithCheckBox
                  onChange={handleChangeRole}
                  values={interimFilters.roles}
                  label={intl.formatMessage({ id: "role" })}
                  menuItems={roleOptions}
                />
              </Box>
              <Box sx={{ width: "230px", marginTop: "16px" }}>
                <BasicTextFieldWithoutForm
                  value={interimFilters.company}
                  label={intl.formatMessage({ id: "company" })}
                  type="text"
                  size="small"
                  handleOnChange={handleChangeCompany}
                  InputLabelProps={{
                    sx: {
                      height: "44px",
                    },
                  }}
                  InputProps={{
                    sx: {
                      height: "44px",
                    },
                  }}
                />
              </Box>
              <Box sx={{ width: "230px", marginTop: "16px" }}>
                <BasicSelectWithCheckBox
                  onChange={handleChangeStatus}
                  values={interimFilters.statuses}
                  label={intl.formatMessage({ id: "status" })}
                  menuItems={statusOptions}
                />
              </Box>
              <Box
                sx={{ width: "230px", marginTop: "16px", textAlign: "right" }}
              >
                <BasicButton
                  variant={"outlined"}
                  onClick={resetFilters}
                  sx={{
                    padding: "12px 16px",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "NotoSans-Regular",
                      fontSize: "14px",
                      letterSpacing: 0,
                      lineHeight: "20px",
                    }}
                  >
                    {intl.formatMessage({ id: "reset" })}
                  </Typography>
                </BasicButton>
                <BasicButton
                  variant={"contained"}
                  onClick={applyFilters}
                  sx={{ marginLeft: "20px", padding: "12px 16px" }}
                >
                  {intl.formatMessage({ id: "apply" })}
                </BasicButton>
              </Box>
            </Box>
          </Popover>
        </Box>
      </Box>
    </Box>
  );
};

export default Filters;
