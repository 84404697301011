import { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import CircleIcon from "@mui/icons-material/Circle";
import Typography from "@mui/material/Typography";
import { useIntl } from "react-intl";
import { ACTIVE, DEACTIVATED } from "../../utils/constants";
import {
  BasicDataTable,
  BasicPagination,
  DarkModeContext,
} from "@datwyler/shared-components";

const headers = [
  "first_name",
  "last_name",
  "id",
  "email",
  "status",
  "role",
  "company",
  "actions",
];

const columnConfigs = {
  checkbox: {
    colspan: 0.75,
    isCentralized: false,
  },
  first_name: {
    colspan: 1,
    isCentralized: false,
  },
  last_name: {
    colspan: 1,
    isCentralized: false,
  },
  id: {
    colspan: 2,
    isCentralized: false,
  },
  email: {
    colspan: 2,
    isCentralized: false,
  },
  status: {
    colspan: 1.25,
    isCentralized: false,
  },
  role: {
    colspan: 1,
    isCentralized: false,
  },
  company: {
    colspan: 2,
    isCentralized: false,
  },
  actions: {
    colspan: 1,
    isCentralized: true,
  },
};

const UserTable = (props) => {
  const {
    userData,
    selectedUsers,
    setSelectedUsers,
    setDialogOpened,
    updateUser,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    totalRows,
  } = props;
  const [tableData, setTableData] = useState([[]]);
  const intl = useIntl();
  const { colors }: any = useContext(DarkModeContext);

  useEffect(() => {
    if (userData) {
      setTableData(getData());
    }
  }, [userData]);

  useEffect(() => {
    setTableData(getData());
  }, [selectedUsers]);

  const isSelected = (user) => {
    if (!selectedUsers) return false;
    return !!selectedUsers.find((s) => s.id === user.id);
  };

  const getData = () => {
    const data = [];
    if (userData) {
      let temp = JSON.parse(JSON.stringify(userData));
      temp?.forEach((user) => {
        data.push({
          isChecked: isSelected(user),
          first_name: user.firstName,
          last_name: user.lastName,
          id: user.id,
          email: user.email,
          status: renderStatus(user),
          role: intl.formatMessage({ id: user.role }),
          company: user.company || "",
          additionalInfo: user,
          moreButtonMenuItems: getMoreButtonMenu(user),
        });
      });
    }

    return data;
  };

  const getMoreButtonMenu = (user) => {
    return [
      {
        label: intl.formatMessage({ id: "update" }),
        value: user,
        onClick: () => {
          setSelectedUsers([user]);
          setDialogOpened("updateUser");
        },
      },
      {
        label:
          user?.status === DEACTIVATED
            ? intl.formatMessage({ id: "reactivate" })
            : intl.formatMessage({ id: "deactivate" }),
        value: null,
        menuItemFontStyle: {
          color:
            user?.status === DEACTIVATED
              ? colors.moreButtonDialogFont
              : colors.moreButtonDeactivateFont,
          fontFamily: "NotoSans-Regular",
          fontSize: "14px",
          letterSpacing: 0,
          lineHeight: "20px",
          "&:hover": {
            color:
              user?.status === DEACTIVATED
                ? colors.moreButtonDialogFont
                : colors.moreButtonDeactivateFont,
          },
        },
        onClick: () =>
          handleUpdateStatus(
            user,
            user?.status === DEACTIVATED ? ACTIVE : DEACTIVATED
          ),
      },
    ];
  };

  const handleUpdateStatus = (user, status) => {
    if (status === DEACTIVATED) {
      setDialogOpened("deactivateUser");
      setSelectedUsers([user]);
    } else {
      setDialogOpened("reactivateUser");
      const submitValues = {
        firstName: user?.firstName,
        lastName: user?.lastName,
        email: user?.email,
        role: user?.role,
        company: user?.company,
        contact: user?.contact,
        status: status,
      };

      updateUser({
        variables: { id: user.id, user: submitValues },
      });
    }
  };

  const renderStatus = (user) => {
    return (
      <Box>
        <Box sx={{ display: "inline-flex", marginRight: "8px" }}>
          <CircleIcon
            sx={{
              color:
                user.status === ACTIVE
                  ? colors.lightBlue500
                  : colors.natural500,
              width: "8px",
              height: "8px",
            }}
          />
        </Box>
        <Box sx={{ display: "inline-flex" }}>
          <Typography
            sx={{
              color: colors.dataTableFont,
              fontFamily: "NotoSans-Regular",
              fontSize: "14px",
              letterSpacing: "0.02px",
              lineHeight: "19px",
            }}
          >
            {intl.formatMessage({ id: user.status.toLowerCase() })}
          </Typography>
        </Box>
      </Box>
    );
  };

  const handleOnCheck = (additionalInfo) => {
    const temp: any = [...tableData];
    temp.map((row) => {
      if (row.additionalInfo.id === additionalInfo.id) {
        row.isChecked = !row.isChecked;

        const tempSelected = [...selectedUsers];

        if (tempSelected.length < 1) {
          tempSelected.push(row.additionalInfo);
        } else {
          const index = tempSelected.findIndex(
            (s) => s.id === additionalInfo.id
          );
          if (index > -1 && !row.isChecked) {
            tempSelected.splice(index, 1);
          } else {
            tempSelected.push(row.additionalInfo);
          }
        }
        setSelectedUsers(tempSelected);
      }
    });
    setTableData(temp);
  };

  const handleCheckAll = (e) => {
    const temp = [...tableData];
    const tempSelected = [];
    temp.map((row: any) => {
      row.isChecked = e.target.checked;
      if (e.target.checked) {
        tempSelected.push(row.additionalInfo);
      }
    });

    setSelectedUsers(tempSelected);
    setTableData(temp);
  };

  const handleClickRow = (additionalInfo) => {
    handleOnCheck(additionalInfo);
  };

  return (
    <Box sx={{ marginTop: "25px" }}>
      <BasicDataTable
        headers={headers}
        data={tableData}
        columnConfigs={columnConfigs}
        handleCheckAll={handleCheckAll}
        handleOnCheck={handleOnCheck}
        handleClickRow={handleClickRow}
      />
      <BasicPagination
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        page={page}
        setPage={setPage}
        totalRows={totalRows}
      />
    </Box>
  );
};

export default UserTable;
