import { useContext } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { useIntl } from "react-intl";
import {
  CloseIcon24p,
  BasicButton,
  DarkModeContext,
} from "@datwyler/shared-components";

const LogoutDialog = (props) => {
  const { isOpen = false, closeDialog, handleLogout } = props;
  const intl = useIntl();
  const { colors }: any = useContext(DarkModeContext);

  const handleClose = () => {
    closeDialog();
  };

  return (
    <Dialog
      maxWidth={"sm"}
      open={isOpen}
      onClose={handleClose}
      PaperProps={{
        sx: { borderRadius: "8px", backgroundColor: colors.cardBg },
      }}
    >
      <DialogTitle sx={{ paddingTop: "26px", paddingBottom: "16px" }}>
        <Typography
          sx={{
            fontFamily: "NotoSans-SemiBold",
            fontSize: 16,
            color: colors.subTitle,
          }}
        >
          {intl.formatMessage({ id: "log_out" })}
        </Typography>
        <IconButton
          onClick={handleClose}
          sx={{ position: "absolute", right: 16, top: 16 }}
        >
          <CloseIcon24p fill={colors.iconColor} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ fontSize: 14, paddingTop: 1, width: 376 }}>
          <Typography
            sx={{
              fontFamily: "NotoSans-Regular",
              fontSize: 14,
              color: colors.fontTitle,
            }}
          >
            {intl.formatMessage({ id: "confirm_logout" })}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions
        sx={{ justifyContent: "flex-start", paddingBottom: "24px" }}
      >
        <Box sx={{ marginLeft: "16px" }}>
          <BasicButton onClick={handleClose} variant={"outlined"}>
            {intl.formatMessage({ id: "cancel" })}
          </BasicButton>
        </Box>
        <Box sx={{ position: "absolute", right: "24px" }}>
          <BasicButton
            onClick={handleLogout}
            variant={"contained"}
            sx={{
              backgroundColor: colors.red500,
              ":hover": {
                backgroundColor: colors.red800,
              },
            }}
          >
            {intl.formatMessage({ id: "log_out" })}
          </BasicButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default LogoutDialog;
