import InputAdornment from "@mui/material/InputAdornment";
import { IconButton } from "@mui/material";
import { ClearIcon } from "@datwyler/shared-components";

export const getClearAdornment = (form, formItemLabel, colors) => {
  const handleOnclick = () => {
    form.setValue(formItemLabel, "");
  };

  return (
    <InputAdornment position="end">
      <IconButton onClick={handleOnclick}>
        <ClearIcon fill={colors.iconColor} />
      </IconButton>
    </InputAdornment>
  );
};
