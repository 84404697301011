import React, { useState } from "react";
import App from "./App";
import {
  GraphQLApiProvider,
  ApiProvider,
  SnackBarProvider,
  useLocale,
  TranslationProvider,
  DarkModeProvider,
  UserProvider,
} from "@datwyler/shared-components";
import { BrowserRouter } from "react-router-dom";

export default function Root(props: any) {
  const { getDefaultLocale, useLocaleListener } = useLocale();
  const [locale, setLocale] = useState(getDefaultLocale());
  useLocaleListener(setLocale);

  return (
    <React.StrictMode>
      <ApiProvider uri={props.SECURED_GATEWAY_URL}>
        <GraphQLApiProvider uri={props.GRAPHQL_URL}>
          <BrowserRouter>
            <TranslationProvider locale={locale}>
              <DarkModeProvider>
                <SnackBarProvider>
                  <UserProvider>
                    <App />
                  </UserProvider>
                </SnackBarProvider>
              </DarkModeProvider>
            </TranslationProvider>
          </BrowserRouter>
        </GraphQLApiProvider>
      </ApiProvider>
    </React.StrictMode>
  );
}
